//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : false !default;


body {
    @include lib-css(background-color, $page__background-color);
}
//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
    width:100%;
    position:relative;
    ul {
        list-style:none;
        padding:0;
        margin:0;
    }
    #switcher-store {
        max-width: 1160px;
        margin: 0 auto;
        width: 100%;
        margin-top: -170px;
        @media only screen and (max-width: 480px) {
            display:none;
        }
    }
    .beforefooter {
        background:url(../images/banniere-before-footer2.jpg) no-repeat top center;
        padding:50px 0 70px 0;
        max-width:1366px;
        margin:0 auto;
        > div.title {
            color:#FFF;
            text-align:center;
            font-size:30px;
            font-family:$fontmonserrat;
            font-weight:100;
            margin-bottom:25px;
            strong {
                font-weight:700;
            }
            em {
                font-style:normal;
                color:#dc344d;
                font-size:30px;
                font-weight:700;
            }
        }
        ul {
            @include clearfix();
            width:100%;
            padding-bottom:5px;
            li {
                width:25%;
                float:left;
                text-align:center;
                color:#FFF;
                div.picto {
                    background:url(../images/ico-before-footer.png) no-repeat 0 0;
                    width:65px;
                    height:65px;
                    margin:0 auto;
                    margin-bottom:25px;
                }
                p {
                    margin-bottom:0;
                    strong {
                        display:block;
                        font-size:18px;
                        font-weight:700;
                        margin-bottom:10px;
                    }
                    span {
                        display:block;
                        font-size:16px;
                    }
                    em {
                        font-style:normal;
                        display:block;
                        text-transform:uppercase;
                        font-weight:100;
                        font-size:12px;
                    }
                }
            }
            li.paiement {
                div.picto {
                    background-position:-334px 0;
                }
                p {
                    em {
                        font-size:16px;
                        font-style:normal;
                        font-weight:700;
                    }
                    span {
                        margin-bottom:10px;
                    }
                }
            }
            li.stock {
                div.picto {
                    background-position:-633px 0;
                    width:68px;
                }
            }
            li.echantillon {
                div.picto {
                    background-position:-972px 0;
                }
            }
            li.livraison {
                p {
                    span {
                        margin-bottom:10px;
                    }
                    span.fr-mtrp {
                        text-transform:uppercase;
                        font-size:12px;
                        line-height:16px;
                    }
                    em {
                        font-size:18px;
                        color:#dc344d;
                        font-weight:700;
                        margin-bottom:5px;
                    }
                    a {
                        text-decoration:none;
                        color:#FFF;
                    }
                }
            }
        }
        .block.newsletter {
            max-width:640px;
            width:100%;
            margin:0 auto;
            .field .control:before {
                display:none;
            }
            .field {
                margin-right:25px;
            }
            input {
                height:50px;
                background:transparent;
                border:2px solid #FFF;
            }
            .action.primary {
                height:51px;
                background:#FFF;
                border:1px solid #000;
                color:#000;
                text-transform:uppercase;
                border-radius:0;
                width:194px;
            }
        }
        @media only screen and (max-width: 960px) {
            background-size:cover;
            ul {
                li {
                    width:50%;
                }
                li:nth-child(odd) {
                    clear:both;
                    float:left;
                }
            }
        }
        @media only screen and (max-width: 768px) {
            padding:35px 15px;
        }
        @media only screen and (max-width: 480px) {
            ul {
                li {
                    width:100%;
                    float:none;
                    clear:both;
                }
            }
            > div.title {
                font-size:24px;
            }
            .block.newsletter {
                .action.primary {
                    width:auto;
                }
                .field {
                    margin-right:5px;
                }
                input {
                    padding-left:0;
                    text-align:center;
                }
            }
        }
    }
    .footer {
        width:100%;
        padding-top:30px;
        max-width:1180px;
        margin:0 auto;
        @include clearfix();
        .left-footer {
            float:left;
            width:47%;
            padding-left:10px;
            > a {
                display:block;
                margin-bottom:10px;
            }
            ul {
                li {
                    font-size:14px;
                    color:#000;
                    font-weight:300;
                    position:relative;
                    padding:0 0 15px 47px;
                    margin:0;
                    a {
                        font-size:14px;
                        color:#000;
                        font-weight:100;
                        text-decoration:none;
                        display:block;
                    }
                }
                li:before {
                    content:"";
                    display:block;
                    background:url(../images/picto-footer.png) no-repeat;
                    width:35px;
                    height:35px;
                    position:absolute;
                    top:2px;
                    left:0;
                }
                li.email:before {
                    background-position:0 -82px;
                    top:2px;
                }
                li.phone:before {
                    background-position:0 -45px;
                    top:-3px;
                }
            }
        }
        .right-footer {
            float:right;
            width:50%;
            padding-bottom:30px;
            ul.links {
                float:left;
                width:50%;
                li.titre {
                    font-size:18px;
                    text-transform:uppercase;
                    font-weight:700;
                    font-family:$fontmonserrat;
                    position:relative;
                    padding:0 0 14px 0;
                    margin-bottom:20px;
                }
                li.titre:after {
                    content:"";
                    display:block;
                    position:absolute;
                    bottom:0;
                    left:0;
                    background:#000;
                    width:38px;
                    height:1px;
                }
                li {
                    a {
                        color:#000;
                        text-decoration:none;
                        font-size:14px;
                        display:block;
                        margin-bottom:4px;
                    }
                }
            }
        }
        @media only screen and (max-width: 960px) {
            padding:30px 15px;
            width:calc( 100% - 30px);
            .left-footer {
                float:none;
                width:auto;
                padding-left:0;
                padding-bottom:25px;
            }
            .right-footer {
                float:none;
                width:auto;
            }
        }
        @media only screen and (max-width: 480px){
            .right-footer {
                ul.links {
                    float:none;
                    width:100%;
                    padding-bottom:15px;
                }
            }
        }
    }
    .after-footer {
        background:#000;
        .inner {
            max-width:1366px;
            margin:0 auto;
        }
        ul.social {
            text-align:center;
            padding:20px 0 15px 0;
            li {
                display:inline-block;
                vertical-align:top;
                padding:0 22px;
                margin:0;
                a {
                    display:block;
                    background:url(../images/facebook.svg) no-repeat 0 0;
                    width:16px;
                    height:16px;
                    background-size:7px auto;
                    font-size:0;
                }
            }
            li.fb {
                a {
                    width:10px;
                }
            }
            li.tweet {
                a {
                    background:url(../images/twitter.svg) no-repeat 0 0;
                    background-size:16px auto;
                }
            }
            li.pint {
                a {
                    background:url(../images/pinterest.svg) no-repeat 0 0;
                    background-size:16px auto;
                }
            }
            li.google {
                a {
                    background:url(../images/google.svg) no-repeat 0 0;
                    background-size:21px auto;
                    width:21px;
                }
            }
            li.inst {
                a {
                    background:url(../images/instagram.svg) no-repeat 0 0;
                    background-size:16px auto;
                    height:17px;
                }
            }

        }
        p.last {
            text-align:center;
            color:#FFF;
            font-size:12px;
            margin:0;
            font-weight:100;
            padding-bottom:15px;
        }
    }
}

.footer {
    &.content {
        border-top: 1px solid $border-color__base;
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            > .header.links {
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;
                float:right;
                position:relative;
                padding-left:88px;
                padding-top:15px;
                > li {
                    font-size: $font-size__base;
                    padding:0;
                    position:relative;
                    &.welcome,
                    a {
                        line-height: 1.4;
                    }
                    a:hover {
                        color:#ff5c5c!important;
                        text-decoration:none;
                    }
                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
                li.compare,
                li.welcome {
                    display:none;
                }
                li:last-child {
                    position:absolute;
                    top:15px;
                    left:0;
                    display:none;
                    a {
                        display:block;
                        background:url(../images/open-newsletter.png) no-repeat top left;
                        width:88px;
                        height:30px;
                        padding-top:32px;
                        text-align:center;
                        color:#000;
                        text-decoration:none;
                        font-size:12px;
                    }
                }
                li:first-child {
                    a {
                        background:url(../images/picto-account.png) no-repeat top center;
                        width:75px;
                        height:30px;
                        padding-top:32px;
                        text-align:center;
                        color:#000;
                        text-decoration:none;
                        display:block;
                        font-size:12px;
                    }
                }
                li.wishlist {
                    a {
                        background:url(../images/picto-wishlist.png) no-repeat top center;
                        width:84px;
                        height:30px;
                        padding-top:32px;
                        text-align:center;
                        color:#000;
                        text-decoration:none;
                        display:block;
                        font-size:12px;
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: $indent__l $indent__base 0;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;
        background:#FFF;
        max-width: 1366px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        margin-top:15px;
        .panel.wrapper {
            .header.links {
                li.welcome {
                    display:none;
                }
                li {
                    a {
                        text-transform:uppercase;
                        font-size:12px;
                        color:#FFF;
                    }
                }
            }
        }
        #custom-header-id {
            padding-left:0;
            padding-right:0;
            position:relative;
        }
        .header.panel {
            @extend .abs-add-clearfix-desktop;
            float:right;
            position:relative;
            padding:0;
            .block.newsletter {
                position:absolute;
                top:68px;
                left:-83%;
                width:100%;
                z-index:5;
                display:none;
                background:#FFF;
                padding:10px 15px 15px 15px;
                border:1px solid #bbb;
                .field {
                    margin-right:0;
                }
                .action.subscribe {
                        width: 35px;
                        height: 32px;
                        background: #ff5501;
                        border: none;
                        padding:0;
                    &:after {
                        content:"ok";
                        display:inline-block;
                        color:#FFF;
                        font-size:12px;
                        text-transform:uppercase;
                    }
                    span {
                        display:none;
                    }
                }
                .title {
                    display:block;
                }
                &::before {
                    content: '';
                    z-index: 99;
                    position: absolute;
                    right: 23px;
                    top: -6px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #fff transparent;
                }
                &::after {
                    content: '';
                    z-index: 98;
                    position: absolute;
                    right: 23px;
                    top: -6px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #d7d7d7 transparent;
                    visibility:hidden;
                }
            }
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
                font-weight:700;
                h1 {
                    font-weight:700;
                }
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: 0 auto 10px 35px;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
.page-main {
    clear:both;
}
.cms-index-index {
    .page-main {
        padding-left:0;
        padding-right:0;
    }
    .column.main {
        padding-bottom:0;
    }
}
.slideshow-container {
    background:#FFF;
    color:#000;
    padding-top:60px;
    .inner {
        max-width:1136px;
        margin:0 auto;
        width:100%;
        display:flex;
        position:relative;
        z-index:1;
    }
    h2 {
        margin:0;
        font-size:30px;
        font-family: "Montserrat", sans-serif;
        font-weight:700;
        text-transform:uppercase;
        text-align:center;
        margin-bottom:25px;
        strong {
            display:block;
            text-transform:none;
            font-weight:500;
            font-size:26px;
        }
    }
    .left-show {
        width:40%;
        text-align:center;
        position:relative;
        .outer {
            background:#FFF;
            position:relative;
            margin-top:120px;
            margin-left:90px;
            padding:15px 0;
        }
        &:before {
            content:"";
            display:block;
            width:340px;
            height:80%;
            border:4px solid #000;
            position:absolute;
            left:-75px;
            top:0;
        }
        p {
            font-size:20px;
            margin:0;
            font-family: "Montserrat", sans-serif;
            font-weight:400;
            text-align:center;
            margin-bottom:35px;
            strong {
                font-weight:700;
            }
        }
        a.bt {
            color:#000;
            font-size:24px;
            border:2px solid #000;
            text-decoration:none;
            display:inline-block;
            padding:10px 15px;
        }
        .scroll-new {
            font-size:14px;
            background:#FFF;
            color:#000;
            border:1px solid #000;
            padding:20px 30px 20px 96px;
            display:inline-block;
            background:#FFF url(../images/fleche-bas.png) no-repeat 20px center;
            text-transform:uppercase;
            -webkit-transform:rotate(-90deg);
            -moz-transform:rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform:rotate(-90deg);
            transform: rotate(-90deg);
            position:absolute;
            left:-115px;
            bottom:0;
            cursor:pointer;
            display:none;
            @media only screen and (max-width: 880px) {
                padding:15px 20px 15px 33px;
                left:-65px;
                background-size:19px auto;
                background-position:10px center;
                bottom:40px;
            }
        }
    }
    .right-show {
        width:51.5%;
        padding-top:45px;
        img {
            position:relative;
            z-index:1;
        }
    }
    @media only screen and (max-width: 1190px) {
        .inner {    
            padding-left:15px;
            padding-right:15px;
            width:auto;
        }   
    }
    @media only screen and (max-width: 1100px) {
         .left-show {
             .outer {
                 margin-top:60px;
             }
        }
    }
    @media only screen and (max-width: 920px) {
        .inner {
            display:block;
        }
        .right-show {
            width:auto;
            padding-top:0;
            text-align:center;
        }
        .left-show {
            width:auto;
            &:before {
                display:none;
            }
            .outer {
                margin:0;
                border:2px solid #000;
                padding:15px;
            }
        }
    }
}
.slideshow-morpho {
    background:#FFF;
    color:#000;
    padding-top:60px;
    margin-bottom:60px;
    .inner {
        max-width:1136px;
        margin:0 auto;
        width:100%;
        display:flex;
        position:relative;
        z-index:1;
    }
    h2 {
        margin:0;
        font-size:33px;
        font-family: "Montserrat", sans-serif;
        font-weight:700;
        text-transform:uppercase;
        text-align:center;
        margin-bottom:35px;
        strong {
            display:block;
            text-transform:none;
            font-weight:500;
            font-size:27px;
        }
        span {
            font-weight:500;
        }
    }
    .left-show {
        width:45%;
        text-align:center;
        position:relative;
        order:1;
        .outer {
            background:#FFF;
            position:relative;
            margin-top:100px;
            margin-left:-60px;
            padding:15px 0;
        }
        &:before {
            content:"";
            display:block;
            width:440px;
            height:103%;
            border:4px solid #000;
            position:absolute;
            right:-160px;
            top:0;
        }
        p {
            font-size:20px;
            margin:0;
            font-family: "Montserrat", sans-serif;
            font-weight:400;
            text-align:center;
            margin-bottom:35px;
            strong {
                font-weight:700;
            }
        }
        a.bt {
            color:#000;
            font-size:24px;
            border:2px solid #000;
            text-decoration:none;
            display:inline-block;
            padding:10px 15px;
        }
        .scroll-new {
            font-size:14px;
            background:#FFF;
            color:#000;
            border:1px solid #000;
            padding:20px 30px 20px 96px;
            display:inline-block;
            background:#FFF url(../images/fleche-bas.png) no-repeat 20px center;
            text-transform:uppercase;
            -webkit-transform:rotate(-90deg);
            -moz-transform:rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform:rotate(-90deg);
            transform: rotate(-90deg);
            position:absolute;
            left:-115px;
            bottom:0;
            cursor:pointer;
            display:none;
            @media only screen and (max-width: 880px) {
                padding:15px 20px 15px 33px;
                left:-65px;
                background-size:19px auto;
                background-position:10px center;
                bottom:40px;
            }
        }
    }
    .right-show {
        width:55%;
        padding-top:48px;
        order:2;
        img {
            position:relative;
            z-index:1;
        }
    }
    @media only screen and (max-width: 1190px) {
        .inner {    
            padding-left:15px;
            padding-right:15px;
            width:auto;
        }   
    }
    @media only screen and (max-width: 1200px) {
         .left-show {
             &:before {

             }
             .outer {
                 margin-top:60px;
                 margin-left:0;
             }
        }
    }
    @media only screen and (max-width: 920px) {
        .inner {
            display:block;
        }
        .right-show {
            width:auto;
            padding-top:0;
            text-align:center;
            order:initial;
        }
        .left-show {
            width:auto;
            order:initial;
            &:before {
                display:none;
            }
            .outer {
                margin:0;
                border:2px solid #000;
                padding:15px;
            }
        }
        h2 {
            strong {
                br {
                    display:none;
                }
            }
        }
    }
}
.bloc-morpho {
    .inner {
        display: flex;
        flex-wrap: wrap;
        background:#FFF;
    }
    .img {
        flex: 0 0 65.5%;
        max-width: 65.5%;
        text-align:right;
        position:relative;
        .deco {
            position:absolute;
            right:0;
            bottom:20px;
        }
    }
    div.txt {
        flex: 0 0 32.5%;
        max-width: 32.5%;
        margin:0 0 0 2%;
        .central {
            display:table;
            height:100%;
            width:100%;
            > div {
                display:table-cell;
                vertical-align:middle;
                padding-right:15px;
            }
        }
        h2 {
            text-transform:uppercase;
            margin:0 0 25px 0;
            span,
            em {
                display:inline-block;
                font-size:80px;
                font-weight:900;
                font-family:$fontmonserrat;
                font-style:normal;
            }
            strong {
                display:inline-block;
                font-size:36px;
                font-weight:300;
                 line-height:90%;
            }
        }
        p {
            font-size:16px;
            margin-bottom:25px;
            line-height:145%;
        }
        a {
            display: inline-block;
            color: #000;
            border: 1px solid #000;
            font-size: 12px;
            text-decoration: none;
            padding: 15px 35px 15px 25px;
            float: left;
            margin-right: 20px;
            span,
            em {
                display: block;
                padding-right: 15px;
                position: relative;
                font-style:normal;
            }
            span:after,
            em:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 4px 4px;
                border-color: transparent transparent transparent #000;
                display: block;
                top: 5px;
                right: 0;
                position: absolute;
            }
        }
    }
    div.deco {
        width:10px;
        &:before {
            display:block;
            width:1px;
            height:161px;
            background:#000;
            content:"";
            margin-left:4px;
        }
        span,
        em {
            display:block;
            background:#000;
            width:8px;
            height:8px;
            border-radius:50%;
            margin:5px 0;
        }
    }
    @media only screen and (max-width: 1030px) {
        div.txt {
            h2 {
                span {
                    font-size:68px;
                }
                strong {
                    font-size:30px;
                }
            }
            .central {
                > div {
                    padding-right:0;
                }
            }
        }
    }
    @media only screen and (max-width: 880px) {
        div.txt {
            flex: 0 0 37.5%;
            max-width: 37.5%;
        }
        .img {
            flex: 0 0 65.5%;
            max-width: 60.5%;
        }
    }
    @media only screen and (max-width: 768px) {
        div.txt {
            flex:inherit;
            max-width:100%;
            padding-bottom:25px;
        }
        .img {
            flex:inherit;
            max-width:100%;
            overflow:hidden;
            img {
                margin:-50px 0;
            }
        }
    }
}
.bloc-phase {
    background:url(../images/banniere-phase.jpg) no-repeat top center;
    color:#FFF;
    .inner {
        max-width:1160px;
        margin:0 auto;
        height:506px;
        .centre {
            display:table;
            height:100%;
            width:100%;
            > .central {
                display:table-cell;
                vertical-align:middle;
            }
        }
    }
    .text {
        max-width:485px;
        position:relative;
        padding-bottom:60px;
        h2 {
            font-size:36px;
            text-transform:uppercase;
            font-family:$fontmonserrat;
            margin:0;
            margin-bottom:30px;
            strong {
                font-weight:900;
                display:block;
            }
        }
        p {
            font-size:16px;
            line-height:145%;
            margin-bottom:40px;
        }
        a.view {
            display: inline-block;
            color: #FFF;
            border: 1px solid #FFF;
            font-size: 12px;
            text-decoration: none;
            padding: 15px 35px 15px 25px;
            margin-right: 20px;
            span,
            em {
                display: block;
                padding-right: 15px;
                position: relative;
                font-style:normal;
            }
            span:after,
            em:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 4px 4px;
                border-color: transparent transparent transparent #FFF;
                display: block;
                top: 5px;
                right: 0;
                position: absolute;
            }
        }
    }
    div.deco {
        width:10px;
        position:absolute;
        bottom:0;
        right:0;
        &:before {
            display:block;
            width:1px;
            height:161px;
            background:#FFF;
            content:"";
            margin-left:4px;
        }
        span,
        em {
            display:block;
            background:#FFF;
            width:8px;
            height:8px;
            border-radius:50%;
            margin:5px 0;
        }
    }
    @media only screen and (max-width: 1190px) {
        .inner {
            padding-left:15px;
            padding-right:15px;
        }
    }
    @media only screen and (max-width: 1024px) {
        background-size:cover;
        .inner {
            height:auto;
            padding:35px 15px;
        }
    }
    @media only screen and (max-width: 768px) {
        .text {
            max-width:100%;
        }
    }
}
.bloc-demo {
    & * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .inner {
        display: flex;
        flex-wrap: wrap;
        background: #FFF;
    }
    div.text {
        flex: 0 0 34.75%;
        max-width: 34.75%;
        padding-left:65px;
    }
    div.img {
        flex: 0 0 65.25%;
        max-width: 65.25%;
    }
    h2 {
            font-size:36px;
            text-transform:uppercase;
            font-family:$fontmonserrat;
            margin:140px 0 25px 0;
            strong {
                font-weight:900;
                display:block;
                margin:0;
                margin-bottom:30px;
            }
    }
    p {
        font-size:16px;
        line-height:145%;
    }
    @media only screen and (max-width: 1190px) {
        div.text {
            padding-left:15px;
        }
    }
    @media only screen and (max-width: 1024px) {
        h2 {
            font-size:30px;
        }
        p {
            br {
                display:none;
            }
        }
        div.img {
            padding-left:15px;
        }
    }
    @media only screen and (max-width: 768px) {
        .inner {
            display:block;
            flex-wrap:inherit;
            padding:35px 15px;
        }
        h2 {
            margin-top:0;
        }
        div.text {
            padding-left:0;
            max-width:100%;
        }
        div.img {
            max-width:100%;
            padding-left:0;
        }
    }
}
.bloc-seo {
    padding-top:90px;
    padding-bottom:45px;
    .inner {
        max-width:1280px;
        margin:0 auto;
        text-align:center;
    }
    h2,
    h1 {
        font-size:30px;
        font-family:$fontmonserrat;
        margin:0 0 15px 0;
        strong {
            font-weight:900;
        }
    }
    h2 {
        text-transform:uppercase;
        font-weight:400;
        font-size:24px;
        text-align:left;
    }
    h3 {
        text-transform:uppercase;
        font-weight:400;
        font-size:18px;
        text-align:left;
    }
    p {
        font-size:14px;
        margin-bottom:25px;
        text-align:left;
        font-family: "Montserrat", sans-serif;
        line-height:145%;
    }
    a.morehome {
        display:block;
        font-size:15px;
        color:#000;
        text-decoration:underline;
        font-weight:700;
        text-align:left;
    }
    div.more {
        display:none;
    }
    @media only screen and (max-width: 1100px) {
        .inner {
            padding-left:15px;
            padding-right:15px;
        }
    }
    @media only screen and (max-width: 1024px) {
        padding-top:45px;
        padding-bottom:45px;
        .inner {
            p {
                br {
                    display:none;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding-top:25px;
        padding-bottom:25px;
        h2 {
            font-size:27px;
        }
        p {
            font-size:16px;
        }
    }
    @media only screen and (max-width: 480px) {
        h2 {
            br {
                display:none;
            }
        }
    }
}
.bloc-prod-w {
    h2 {
        text-align:center;
        font-size:36px;
        text-transform:uppercase;
        font-family:$fontmonserrat;
        font-weight:300;
        margin-bottom:45px;
        span,
        em {
            display:inline-block;
            text-align:left;
            background:url(../images/fleche-silver.png) no-repeat bottom center;
            padding-bottom:40px;
            font-style:normal;
        }
        strong {
            display:block;
            text-align:center;
            font-weight:900;
        }
    }
}

/* Styles hors homepage */
body:not(.cms-home) {
    .nav-sections {
        margin-bottom:35px;
    }
}
@media only screen and (max-width: 768px) {
    .logo img {
        max-width:145px;
        visibility:hidden;
    }
    .logo {
        background:url(../images/protifast.png) no-repeat 0 0;
        background-size:100% auto;
        margin-left:55px;
    }
    .nav-toggle:before,
    .block-search .label:before,
    .minicart-wrapper .action.showcart:before {
        color:#000;
    }
    .mobile-link .minicart-wrapper {
        margin-top:0;
    }
    .page-header {
        margin-bottom:0;
        border:none;
    }
    #custom-header-id {
        position:relative;
    }
}
.cms-abecedaire-regime {
    .page-main {
        h3 {
            text-transform:uppercase;
        }
        p {
            font-size:14px;
            margin-bottom:35px;
        }
    }
    h3 strong {
        line-height:30px!important;
    }
}
@media only screen and (min-width: 768px) {
    body.cms-proteines-de-qualite {
        .column.main {
            width: 70%;
            margin-left:25%;
        }
        h1.page-title {
            text-transform:uppercase;
        }
        .page-main {
            p {
                font-size:14px;
                line-height:24px;
                margin-bottom:25px;
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    body.cms-qui-sommes-nous,
    body.cms-sachets-hyperproteines {
        #maincontent {
            width: 70%;
            margin-left:25%;
        }
        h1.page-title {
            text-transform:uppercase;
        }
        .page-main {
            p {
                font-size:14px;
                line-height:24px;
                margin-bottom:25px;
            }
        }
    }
}
.recipes-table {
    width: 100%;
}

.recipes-table th {
    background: #6e6f71;
    color: #FFF;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    padding: 3px 0;
    font-family: 'museo';
}

.recipes-table tr.bottom th {
    background: #c5c6c8;
    color: #000;
    text-transform: none;
}

.recipes-table tbody {
    border-top: 15px solid #FFF;
}

.recipes-table tr td {
    color: black;
    font-family: Raleway;
    font-weight: 300;
    border-right: 10px solid #FFF;
    border-bottom: 2px solid #FFF;
    padding: 10px 25px;
    font-size: 13px;
}

.recipes-table tr td.blue-2 {
    background-color: #009ee0;
}

.recipes-table tr td.title {
    color: #FFF;
    font-family: "DJ Fancy";
}

.recipes-table tr.blue td {
    background-color: #d9f1fa;
}

.recipes-table tr td strong {
    font-weight: normal;
    font-family: 'museo_bold';
}

.recipes-table tr.blue td:nth-child(1),
.recipes-table tr.blue td.extra {
    background-color: #4cc8f2;
}

.recipes-table tr.vert td {
    background-color: #e5eacd;
}

.recipes-table tr.vert td:nth-child(1),
.recipes-table tr.vert td.extra {
    background-color: #bfca7e;
}

.recipes-table tr.vert td.vert-2 {
    background-color: #9cb135;
}

.recipes-table tr.rouge td {
    background-color: #fde0d1;
}

.recipes-table tr.rouge td:nth-child(1),
.recipes-table tr.rouge td.extra {
    background-color: #fac4ac;
}

.recipes-table tr.rouge td.rouge-2 {
    background-color: #ec432e;
    vertical-align:middle;
}

.recipes-table tr.rouge td.rouge-2 span {
    display: block;
    width: 76px;
    text-align: center;
    line-height: 190%;
}

.recipes-table tr td.blue-2.title,
.recipes-table tr td.vert-2.title {
    text-align: center;
    vertical-align:middle;
}

.recipes-table tr td.fleche {
    position: relative;
}

.recipes-table tr td.fleche img {
    position: absolute;
    top: 24px;
    left: 5px;
    height: 70px;
}

.recipes-table tr td.fleche img.free {
    position: absolute;
    top: 15px;
    left: 5px;
    height: 82px;
}

.recipes-table tr.violet td.fleche img {
    position: absolute;
    top: 16px;
    left: 5px;
    height: 44px;
}

.recipes-table tr.violet td.fleche img.free {
    position: absolute;
    top: 16px;
    left: 5px;
    height: 81px;
}

.recipes-table tr.violet td {
    background-color: #ece1ef;
}

.recipes-table tr.violet td:nth-child(1) {
    background-color: #ddc7e0;
}

.recipes-table tr.violet td.violet-2 {
    background-color: #a961a4;
    line-height: 190%;
    text-align: center;
    vertical-align:middle;
}

.recipes-table tr.violet td.extra {
    background-color: #c498c4;
}

.recipes-table tr.violet td:last-child,
#table-page-1.recipes-table tr td:last-child {
    border-right: 0!important;
}

p.legende {
        font-size: 13px;
        font-weight: normal;
        margin: 50px 0;
        float: right;
        line-height: 30px;
    }
    
p.legende span {
        display: inline-block;
        border: 1px solid #000;
        border-right: none;
        vertical-align: top;
        padding: 2px 50px 2px 5px;
        line-height: 23px;
        margin-left: 20px;
        font-weight:bold;
    }
    
p.legende span img {
        display: inline-block;
        vertical-align: top;
    }
.cms-page-view .columns {   
    .title-black {
            background: url(../images/bg-noir.png) repeat-x 0 0;
            height: 87px;
            margin-bottom: 55px;
            color: #FFF;
            padding: 20px 60px 0 60px;
        }

    .title-black span {
            float: left;
            font-size: 28px;
            line-height: normal;
            transform: rotate(-90deg);
        }

    .title-black p {
            float: left;
            padding: 0 0 0 20px;
            font-size: 29px;
            color: #FFF;
            margin: 0;
            line-height: normal;
            font-family: 'museo_bold';
        }

    .title-black p strong {
            font-size: 15px;
            font-weight: normal;
            padding-left: 3px;
            font-family: "DJ Fancy";
        }

    .title-black span.right {
            float: right;
        }
}
@media (min-width: 1200px) {
    .recipes-table th {
        white-space:nowrap;
    }
}
@media screen and (max-width: 1024px) {
    .recipes-table tr td {
        padding:10px 20px;
    }
}
@media screen and (max-width: 980px) {
  .recipes-table tr td {
    border-right: 2px solid #FFF;
    font-size:12px;
  }
  .recipes-table th {
    font-size:13px;
  }
}
@media screen and (max-width: 768px) {
  .recipes-table th {
    font-size:11px;
  }
  .std .title-black {
    padding:20px 30px 0 30px;
  }
  .recipes-table tr td.fleche img {
    display:none!important;
  }
  p.legende {
    margin:25px 0;
  }
  .col-phase .phase-inner {
    float:none;
    width:auto;
    margin-bottom:15px;
  }
  .col-phase .phase-inner .bloc-black {
    margin-top:0!important;
    margin-bottom:7px!important;
  }
  .col-phase .phase-inner h3 {
    line-height:normal;
  }
}
@media screen and (max-width: 670px) {
    .maxi-table {
        width:100%;
        overflow:auto;
    }
    .recipes-table tr td {
        padding:5px 10px;
    }
    .recipes-table th {
        font-size:9px;
    }
    p.legende span {
        padding-right:15px;
    }
}
@media screen and (max-width: 380px) {
    .title-black {
        padding: 20px 15px 0 15px;
        height:69px;
        background-position:bottom left;
        margin-bottom:28px;
    }
    .title-black p {
        padding-left:10px;
        font-size:18px;
    }
    .title-black p strong {
        font-size:12px;
        padding-left:4px;
    }
    .title-black span {
        font-size:20px;
    }
    p.legende span {
        display:block;
        margin-left:0;
    }
    p.legende {
        font-size: 11px;
    }
    p.legende span {
        margin-left:0;
    }
}

.col-phase .phase-inner {
    float:left;
    width:31.75%;
    font-family: 'museo';
    padding:0 10px;
}
.col-phase .phase-inner strong {
    font-weight:normal;
    font-family: 'museo_bold';
}
.col-phase .phase-inner h2.titre {
    background:url(../images/border-rouge.png) repeat-x 0 0;
    height: 48px;
    background-size: auto 48px;
    color:#FFF;
    font-size:14px;
    text-transform:none;
    padding:18px 13px 0 17px;
    margin:0 -10px;
}
.col-phase .phase-inner h2.titre strong {
    font-weight:normal;
    font-family: 'museo_bold';
}
.col-phase .phase-inner h2.titre span {
    float:right;
    font-size:10px;
    padding-top:7px;
}
.col-phase .phase-inner h2.titre span strong {
    font-weight:normal;
    font-family: 'museo_bold';
}
.col-phase .phase-inner .phase-top {
    padding:10px 0 10px 0;
}
.col-phase .phase-inner p {
    margin:0;
    font-family: 'museo';
    line-height:inherit;
}
.col-phase .phase-inner .phase-top p.left {
    color:#e23724;
    font-size:9px;
    float:left;
    text-align:right;
    width:54%;
    word-spacing: -1px;
    border-right:1px solid #e23624;
    line-height: 128%;
    padding-right: 10px;
}
.col-phase .phase-inner .phase-top p.img {
    float:right;
    width:40%;
    text-align:left;
}
.col-phase .phase-inner .phase-top img {
    max-width:100%;
}
.col-phase .phase-inner p.black {
    font-size:14px;
    text-align:right;
    color:#000;
    font-family: 'museo_bold';
}
.col-phase .phase-inner p.fleche {
    font-size:8px;
    color:#e23724;
    line-height:125%;
    float:right;
}
.col-phase .phase-inner p.fleche span {
    display:inline-block;
    height: 15px;
    font-size: 10px;
    vertical-align: top;
    padding-right: 5px;
}
.col-phase .phase-inner p.fleche strong {
    display:inline-block;
    vertical-align:top;
}
.col-phase .phase-inner h3 {
    clear:both;
    color:#e23724;
    font-family:"DJ Fancy";
    text-transform:none;
    font-size:18px;
    line-height:100%;
    margin:0;
    padding:10px 0 8px 0;
}
.col-phase .phase-inner .bloc {
    border:2px solid #e4452b;
    border-radius:6px;
    margin-bottom:7px;
}
.col-phase .phase-inner .inner-bloc {
    border-bottom:2px solid #e4452b;
    padding:5px 9px;
    position:relative;
}
.col-phase .phase-inner .inner-bloc img.picto {
    position:absolute;
    top:8px;
    right:8px;
}
.col-phase .phase-inner .inner-bloc.last {
    border-bottom:none;
}
.col-phase .phase-inner .inner-bloc h4 {
    text-transform:none;
    margin:0;
    font-size:11px;
    font-family: 'museo_bold';
}
.col-phase .phase-inner .inner-bloc p {
    font-size:10px;
    line-height:inherit;
    font-family: 'museo';
}
.col-phase .phase-inner .inner-bloc p strong {
    color:#e23724;
    font-family: 'museo_bold';
}
.col-phase .phase-inner .bloc-black {
    background:#110315;
    border-radius:8px;
    padding:7px 13px 3px 13px;
    text-align:center;
    margin-bottom:7px;
}
.col-phase .phase-inner .bloc-black p {
    font-size:10px;
    color:#FFF;
    padding:0 0 5px 0;
}
.col-phase .phase-inner .bloc-black p.last {
    padding-bottom:0;
    line-height:140%;
}
.col-phase .phase-inner .bloc-simple {
    padding:7px 9px;
    position:relative;
}
.col-phase .phase-inner .bloc-simple.bloc-liste {
    padding:2px 9px;
    word-spacing:-1px;
}
.col-phase .phase-inner .bloc-simple h4 {
    text-transform: none;
    margin: 0;
    font-size: 11px;
    font-family: 'museo_bold';
    padding-left:35px;
}
.col-phase .phase-inner .bloc-simple p {
    font-size: 10px;
    line-height:normal;
    padding-left:35px;
}
.col-phase .phase-inner .bloc-simple p em {
    font-style:normal;
    font-family: 'museo_bold';
    font-size:11px;
}
.col-phase .phase-inner .bloc-simple p.extra span {
    font-family: 'museo_bold';
    font-size:11px;
}
.col-phase .phase-inner .bloc-simple p.extra strong {
    color:#ae5c99;
}
.col-phase .phase-inner .bloc-simple p .red {
    color: #e23724;
    font-family: 'museo_bold';
}
.col-phase .phase-inner .bloc-simple p .orange {
    color: #f0941d;
    font-family: 'museo_bold';
}
.col-phase .phase-inner .bloc-simple p .violet {
    color: #ae5c99;
    font-family: 'museo_bold';
}
.col-phase .phase-inner img.picto {
    width:23px;
}
.col-phase .phase-inner .bloc-simple img.picto {
    position:absolute;
    top:8px;
    left:8px;
}
.col-phase .phase-inner .bloc-simple.bloc-liste h4{
    padding-bottom:2px;
}
.col-phase .phase-inner .bloc-simple.bloc-liste p {
    padding-left:0;
    text-indent:33px;
    line-height:normal;
}
.col-phase .phase-inner.phase-orange h2.titre{
        background-image: url(../images/border-orange.png);
}
.col-phase .phase-inner.phase-orange .phase-top p.left {
    color:#f0941d;
    border-color:#f0941d;
}
.col-phase .phase-inner.phase-orange p.fleche strong,
.col-phase .phase-inner.phase-orange h3,
.col-phase .phase-inner.phase-orange .inner-bloc p strong,
.col-phase .phase-inner.phase-orange .bloc-liste p strong {
    color:#f0941d;
} 
.col-phase .phase-inner.phase-orange .bloc,
.col-phase .phase-inner.phase-orange .inner-bloc {
    border-color:#f0941d;
}
.col-phase .phase-inner.phase-orange .bloc-black p {
    line-height:15px;
}
.col-phase .phase-inner.phase-violet h2.titre{
        background-image: url(../images/border-violet.png);
}
.col-phase .phase-inner.phase-violet .phase-top p.left {
    color:#ae5c99;
    border-color:#ae5c99;
}
.col-phase .phase-inner.phase-violet p.fleche strong,
.col-phase .phase-inner.phase-violet h3,
.col-phase .phase-inner.phase-violet .inner-bloc p strong,
.col-phase .phase-inner.phase-violet .bloc-liste p strong {
    color:#ae5c99;
} 
.col-phase .phase-inner.phase-violet .bloc,
.col-phase .phase-inner.phase-violet .inner-bloc {
    border-color:#ae5c99;
}

.col-phase .phase-inner .bloc-simple .relative {
    position:relative;
}
.col-phase .phase-inner .bloc-simple .relative img.picto {
    top:0;
    left:0;
}
.col-phase .phase-inner .bloc-simple .relative img.picto2 {
    top:28px;
}
.col-phase .phase-inner p.remark {
    color:#8f9092;
    font-size:10px;
    text-align:center;
}
.col-phase .form-img img {
    max-width:100%;
}
.col-phase {
    @include clearfix();
    .phase-inner.phase-violet {
        .bloc-simple p,
        p {
            line-height:137.25%;
        }
    }
}
@media screen and (max-width: 768px) {
  .col-phase .phase-inner {
    float:none;
    width:auto;
    margin-bottom:15px;
  }
  .col-phase .phase-inner .bloc-black {
    margin-top:0!important;
    margin-bottom:7px!important;
  }
  .col-phase .phase-inner h3 {
    line-height:normal;
  }
}
.cms-livres-regime .block-nutritionnistes ul li {
    display:inline;
    margin:0 10px 0 0;
}
.cms-livres-regime div.book {
    float:left;
    width:30%;
    padding-bottom:15px;
}
.cms-livres-regime div.book img {
    width:60px;
    height:84px;
}
.cms-livres-regime div.book h4 {
    margin:0;
    padding:15px 0 0 0;
    text-transform:uppercase;
    font-family: "museo", "Helvetica Neue", Verdana, Arial, sans-serif;
    a {
        color:#333;
    }
}
.cms-livres-regime div.book p {
    font-size:14px;
}
.inner-book {
    padding-top:15px;
    padding-bottom:30px;
}
.cms-livres-regime {
    .main {
        h1 {
            text-transform:uppercase;
            font-size:32px;
            font-weight:700;
            color:#ff5c5c;
        }
        > p {
            font-size:16px;
            margin-bottom:40px;
        }
        ul {
            margin:0;
            padding:0;
            padding-bottom:15px;
            font-weight: 700;
            li {
                a {
                    color:#333;
                    font-size:16px;
                }
            }
        }
    }
}
@media only screen and (max-width: 479px) {
.cms-livres-regime div.book {
    float:none;
    width:100%;
}
}
@media only screen and (min-width: 768px) {
    body.cms-alain-delabos,
    body.cms-jean-marie-marineau,
    body.cms-jean-claude-houdret,
    body.cms-jean-charles-theresy,
    body.cms-pierre-dukan {
        .column.main {
            width: 45%;
            margin-left:25%;
        }
        h1.page-title {
            text-transform:uppercase;
        }
        .page-main {
            p {
                font-size:14px;
                line-height:20px;
                margin-bottom:25px;
                a {
                    color:#333;
                }
            }
        }
        h3,
        h2 {
            font-family: "museo", "Helvetica Neue", Verdana, Arial, sans-serif;
            font-size:18px;
            font-weight:400;
            text-transform:uppercase;
        }
    }
}
.legume_auto {
    h1 {
        text-transform:uppercase;
        font-weight:700;
        color: #ff5c5c;
    }
    p {
        font-size:14px;
        margin-bottom:25px;
    }
    h2 {
        margin:0;
        text-transform:uppercase;
        font-size:18px;
        font-weight:700;
        font-family: "museo", "Helvetica Neue", Verdana, Arial, sans-serif;
    }
    p.sous-titre {
        font-size:14px;
        font-weight:700;
    }
}
.cms-marques-de-proteines {
    .page-main {
        h1 {
            text-transform:uppercase;
            font-weight:700;
        }
        p {
            font-size:14px;
            margin-bottom:25px;
        }
        h2 {
            margin:0;
            text-transform:uppercase;
            font-size:22px;
            font-weight:700;
            font-family: "museo", "Helvetica Neue", Verdana, Arial, sans-serif;
            margin-bottom:15px;
        }
        ul {
            font-size:14px;
        }
    }
}
.top-infos {
    background:#FFF;
    color:#000;
    display:none;
    .inner {
        text-align:center;
        padding:0;
        padding-top:4px;
        font-family:"Montserrat";
        div {
            display:inline-block;
        }
        em {
            font-style:normal;
            font-size:24px;
            font-weight:700;
            color:#ff5c5c;
            text-transform:uppercase;
            vertical-align:top;   
            display:inline-block;
            padding:0 13px 0 0;
        }
        strong {
            display:inline-block;
            font-weight:300;
            background:#000;
            color:#FFF;
            text-transform:uppercase;
            font-size:18px;
            vertical-align:top;
            padding:3px 15px 3px 5px;
            display:inline-block;
            margin-right:10px;
        }
        div {
            font-size:24px;
            font-weight:700;
            vertical-align:top;
        }
        @media only screen and (max-width: 768px) {
            em {
                font-size:18px;
            }
            strong {
                font-size:16px;
            }
            div {
                font-size:18px;
            }
        }
         @media only screen and (max-width: 480px) {
            em {
                font-size:14px;
            }
            strong {
                font-size:12px;
                padding:2px 10px 2px 5px;
            }
            div {
                font-size:14px;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .page-header {
        .block-search .control {
           border:none;
           margin:0;
           padding:0;
        }
        .block-search input {
            margin-top:0;
        }
        .block.newsletter {
            display:none!important;
        }
    }
}
.nav-sections-item-content .avis-header {
    display:none;
}
.catalog-category-view {
    div.column {
        > h2 {
            font-family: "museo", "Helvetica Neue", Verdana, Arial, sans-serif;
            font-size: 24px;
            font-weight: 400;
            font-style: normal;
            line-height: 1.2;
            text-transform:uppercase;
            padding:0;
            margin:0 0 15px 0;
        }
        > p {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            color: #000000;
            font-size: 14px;
            line-height: 1.5;
            padding:0;
            margin:0 0 20px 0;
        }
    }   
}
.product.info.detailed {
    .lst-fc {
        pre {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            color: #000000;
            font-size: 14px;
            white-space:inherit!important;
        }
    }
}
@media only screen and (min-width: 768px) {
    .nav-sections-item-content {
        position:relative;
        .avis-header {
            position:absolute;
            top:0;
            right:15px;
            background:#ff5c5c;
            width:245px;
            height:45px;
            display:none;
            z-index:3;
            color:#FFF;
            strong {
                display:block;
                float:left;
                height:45px;
                background:#FFF;
                font-size:14px;
                color:#000;
                font-weight:700;
                width:56px;
                text-align:center;
                line-height:42px;
                font-family:"Montserrat";
            }
            a {
                display:block;
                color:#FFF;
                text-decoration:none;
                font-size:25px;
                width:calc(100% - 56px);
                text-align:center;
                float:left;
                padding:6px 0 0 0;
                font-family: 'Sacramento', cursive;
            }
        }
    }
    div.nav-sections {
        padding-bottom:26px;
        max-width:100%;
    }
    .cms-home div.nav-sections,
    .catalog-category-view div.nav-sections,
    .catalog-product-view div.nav-sections {
        background:url(../images/frise.png) repeat-x bottom left;
    }
}
.cms-page-view {
    .page-title-wrapper {
        h1 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            text-transform:uppercase;
            font-size: 24px;
        }
    }
    .columns {
        p {
            color: #000000;
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            font-size: 14px;
            line-height: 24px;
            margin-top:0;
            margin-bottom:25px;
        }
    }
}
.cms-mention-legale,
.cms-donnees-personnelles-cookies,
.cms-conditions-generales-vente,
.cms-livraison {
    h1 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            text-transform:uppercase;
            font-size: 24px;
            font-weight:400;
            margin-bottom:25px;
        }
     h2 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            text-transform:uppercase;
            font-size: 18px;
         margin-bottom:10px;
         font-weight:400;
         margin-top:0;
        }
    h3 {
        font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
        text-transform:uppercase;
        font-size: 18px;
        font-weight:400;
    }
}
.cms-abecedaire-regime {
    h1 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            text-transform:uppercase;
            font-size: 24px;
            font-weight:400;
            margin-bottom:25px;
        }
     h2 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            text-transform:uppercase;
            font-size: 18px;
         margin-bottom:10px;
         font-weight:400;
         margin-top:0;
        }
    h3 {
        font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
        text-transform:uppercase;
        font-size: 18px;
        font-weight:400;
        strong {
            display: inline-block;
            font-weight: 400;
            background: #ff5c5c;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 33px;
            color: #FFF;
            text-align: center;
        }
    }
}
.cms-avis {
    .column.main {
        h1 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            text-transform:uppercase;
            font-size: 24px;
            font-weight:400;
            margin-bottom:25px;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            font-weight: 400;
            margin-top: 35px;
        }
        p {
            margin-bottom:35px;
            font-size:16px;
            span {
                font-size:16px;
                strong {
                    font-size:18px;
                    text-transform:uppercase;
                }
            }
        }
        > div {
            font-size:16px;
        }
    }
}
.breadcrumbs .items {
    font-size:14px;
}
.footer-bouger {
	background: #ececec;
	padding: 35px 0;
	text-align: center;
}
.footer-bouger p {
	font-size: 14px;
	font-weight: normal;
	color: #5c5c5c;
	word-spacing: 1px;
    margin:0;
    padding:0 15px;
}
.footer-bouger p a {
	color: #5c5c5c;
	text-transform: none;
}
.page-footer .footer .left-footer ul li a,
.page-footer .footer .left-footer ul li {
    font-weight:400;
}
/*body.checkout-cart-index #cart-totals tr.totals-tax {
    display:none;
}*/
body.checkout-onepage-success .page-title-wrapper a.print {
    display:none;
}
body.catalog-product-view {
    .column {
        .product.media {
            > p {
                font-size:14px;
                 font-family: "Montserrat";
                font-weight:400;
            }
        }
    }
}