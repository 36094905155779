$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/owl';
//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
    p {
        font-size:18px;
        margin-bottom:35px;
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
    .lst-fc {
        padding-bottom:35px;
        font-family: "Montserrat";
        font-weight:400;
        max-width:1066px;
        h2 {
            font-size:36px;
            text-transform:uppercase;
            font-weight:400;
            font-family: "Montserrat";
            margin:0 0 15px 0;
            strong {
                font-weight:900;
                font-size:80px;
                vertical-align:middle;
            }
        }
        p {
            font-size:14px;
        }
    }
    .lst-compo {
        font-size:14px;
        p {
            
        }
        .table {
            max-width:1066px;
            border:1px solid #999999;
            border-bottom:4px solid #ff5c5c;
            tr.titre {
                th {
                    text-align:center;
                    background: #FFF !important;
                    font-size: 16px;
                    letter-spacing: 3px;
                    font-weight:400;
                    text-transform:uppercase;
                }
            }
            tr.sous-titre {
                background:#d73030;
                th {
                    color:#FFF;
                    font-size:14px;
                    padding:8px 15px;
                    font-weight:400;
                    border:none;
                }
            }
            tr {
                td.red {
                    span {
                        background: #ff5c5c;
                        display: inline-block;
                        border-radius: 50%;
                        width: 25px;
                        height: 25px;
                        line-height: 26px;
                        text-align: center;
                        font-family: "museo", "Helvetica Neue", Verdana, Arial, sans-serif;
                        color: #FFF;
                        font-size: 12px;
                    }
                }
            }
            tbody {
                tr:nth-child(odd) {
                    background:#f6a5a5;
                }
                tr {
                    td {
                        font-size:14px;
                        font-weight:400;
                        padding:8px 15px;
                    }
                }
            }
        }
        .table-cols {
            padding-top:25px;
            @include clearfix();
            width:100%;
            .first-tab {
                float:left;
                width:48%;
            }
            .last-tab {
                float:right;
                width:48%;
            }
            p.notice {
                margin-top:15px;
            }
        }
        h6 {
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            margin-top: 25px;
            margin-bottom: 15px;
        }
        @media only screen and (max-width: 640px) {
            .table-cols {
                .first-tab,
                .last-tab {
                    float:none;
                    width:auto;
                }
            }
        }
        @media only screen and (max-width: 380px) {
            .table {
                tr.sous-titre {
                    th {
                        font-size:12px;
                    }
                }
                tbody tr {
                    td {
                        font-size:12px;
                    }
                }
            }
        }
    }
    #tab-description {
        h2 {
            font-family: "Montserrat", "Helvetica Neue", Verdana, Arial, sans-serif;
            font-size: 18px;
        }
        > div {
            font-size:14px;
        }
    }
    .lst-rc {
        h6 {
            margin:0;
            text-transform:uppercase;
            font-size:14px;
            font-weight:700;
            margin-top:25px;
            margin-bottom:15px;
        }
        ul {
            padding:0;
            margin:0;
            font-size:14px;
            list-style-position:inside;
            li {
                margin:0;
            }
        }
    }
    .lst-livraison {
        p.infos {
            font-size:16px;
            color:#5c5c5c;
            margin-bottom:30px;
        }
        ul {
            list-style:none;
            padding:0;
            margin:0;
            max-width:640px;
            li {
                width:100%;
                margin-bottom:30px;
                display:flex;
                flex-wrap: wrap;
                .image {
                    flex: 0 0 121px;
                    background-color:#ff5c5c;
                    > div {
                        display:table;
                        vertical-align:middle;
                        height:100%;
                        > p {
                            display:table-cell;
                            margin:0;
                            vertical-align:middle;
                        }
                    }
                }
                .inner {
                    flex: 0 0 calc(100% - 121px);
                    box-sizing:border-box;
                    padding-left:14px;
                    h3 {
                        margin:0;
                        text-transform:uppercase;
                        font-size:14px;
                        font-weight:700;
                        margin-bottom:15px;
                    }
                    p {
                        font-size:14px;
                        font-weight:400;
                        margin-bottom:20px;
                        strong {
                            display:block;
                            font-size:14px;
                            font-weight:700;
                        }
                        em {
                            font-style:normal;
                            color:#ff5c5c;
                        }
                    }
                    p:last-child {
                        margin-bottom:0;
                    }
                    .half {
                        p {
                            margin-bottom:10px;
                            strong {
                                font-size:13px;
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 440px) {
            ul {
                li {
                    display:block;
                    .image {
                        background:transparent;
                        margin-bottom:15px;
                        width:auto;
                    }
                    .inner {
                        padding-left:0;
                        width:auto;
                    }
                }
            }
        }
        @media only screen and (max-width: 380px) {
            
        }
    }
    .lst-garanti {
        ul {
            list-style:none;
            padding:0;
            margin:0;
            max-width:475px;
            li {
                @include clearfix();
                width:100%;
                margin-bottom:15px;
                img {
                    float:left;
                }
                .inner {
                    float:right;
                    width:calc(100% - 135px);
                    h3 {
                        margin:0;
                        text-transform:uppercase;
                        font-size:14px;
                        font-weight:700;
                        margin-bottom:5px;
                    }
                    p {
                        font-size:14px;
                        font-weight:300;
                        margin-bottom:0;
                    }
                    p.extra {
                        margin-bottom:0;
                        font-weight:700;
                        a {
                            text-decoration:none;
                            color:#ff5c5c;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 380px) {
            ul {
                li {
                    img {
                        max-width:80px;
                    }
                    .inner {
                        width: calc(100% - 80px);
                    }
                }
            }
        }
    }
    .lst-des {
        h6 {
            margin:0;
            text-transform:uppercase;
            font-size:14px;
            font-weight:700;
            margin-top:25px;
            margin-bottom:15px;
        }
        ul,
        ol {
            padding:0;
            margin:0;
            font-size:14px;
            list-style-position:inside;
            li {
                margin:0;
                p {
                    margin:0;
                    display:inline;
                }
            }
        }
    }
    .lst-avis {
        max-width:1366px;
        padding-bottom:0;
        h2 {
            margin-bottom:35px;
        }
        .lst-avis-inner {
            color:#FFF;
            width:100%;
            background:#000;
            padding:45px 20px 125px 45px;
            box-sizing:border-box;
            .slider {
                
            }
            .item {
                .side {
                    max-width:465px;
                    background:url(../images/left-quote.png) no-repeat 0 0;
                    padding:25px 0 0 55px;
                    box-sizing:border-box;
                    margin-left:45px;
                }
                p.txt {
                    font-family:"Myriad Pro";
                    font-size:14px;
                    line-height:145%;
                    margin-bottom:0;
                }
                p.advice {
                    margin-top:15px;
                    font-size:14px;
                    font-weight:100;
                    font-family: "Montserrat";
                    strong {
                        text-transform:uppercase;
                        font-weight:700;
                    }
                }
            }
            .owl-carousel {
                position:relative;
                .owl-nav.disabled {
                    display:block;
                    position:absolute;
                    top: calc(50% - 17px);
                    left:0;
                    width:100%;
                }
                .owl-dots {
                    display:none;
                }
                .owl-next {
                    float:right;
                    background:url(../images/picto-slider.png) no-repeat bottom left;
                    border:none;
                    width:19px;
                    height:35px;
                    span {
                        display:none;
                    }
                }
                .owl-prev {
                    float:left;
                    background:url(../images/picto-slider.png) no-repeat 0 0;
                    border:none;
                    width:19px;
                    height:35px;
                    span {
                        display:none;
                    }
                }
            }
        }
        @media only screen and (max-width: 768px) {
            .lst-avis-inner {
                padding:45px 20px 75px 25px;
                .item {
                    .side {
                        margin-left:25px;
                    }
                }
            }
        }
        @media only screen and (max-width: 600px) {
            h2 {
                font-size:28px;
                strong {
                    font-size:60px;
                }
            }
            .lst-avis-inner {
                padding:35px 15px 55px 15px;
                .item {
                    .side {
                        padding:25px 40px 0 40px;
                    }
                }
            }
        }
        @media only screen and (max-width: 470px) {
            h2 {
                font-size:24px;
                strong {
                    font-size:37px;
                }
            }
        }
        @media only screen and (max-width: 380px) {
            h2 {
                font-size:20px;
                strong {
                    font-size:34px;
                }
            }
            .lst-avis-inner {
                padding:35px 15px 55px 15px;
                .item {
                    p.txt {
                        font-size:12px;
                    }
                }
            }
        }
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
            display:block;
            margin-right:0;
            text-align:center;
            font-family: "Montserrat";
            font-weight:400;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);
                display:none;
                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
        line-height:normal;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size(12);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size(12);
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            @include lib-font-size(18);
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
            .label {
                font-size:14px;
                padding-top:17px;
            }
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 30px;
            height: $tocart-input-size;
            text-align: center;
            width: $tocart-input-size;
            border-color:#000;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: block;

            .field.qty {
                display: block;
                padding-right:0;
                text-align:center;
            }

            .actions {
                display: table-cell;
                padding-top: $indent__m;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
        .box-tocart {
            .actions {
                padding-top:0;
                display:block;
                padding-right:20px;
                padding-left:20px;
                .action.primary {
                    width:100%;
                    background:#fd1f55;
                    color:#FFF;
                    font-size:18px;
                    font-weight:700;
                    font-family: Montserrat;
                    border:none;
                    span {
                        display:inline-block;
                        background: url(../images/picto-cart.png) no-repeat top right;
                        padding:4px 60px 4px 0;
                    }
                }
                .action.primary:hover {
                    border:none;
                    background:#000;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 31%;
            margin-right:4%;
            border:4px solid #000;
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
            padding:18px 25px;
            box-sizing:border-box;
            h2,
            p.titre {
                margin:0;
                font-size:24px;
                font-weight:400;
                font-family: "Montserrat";
                text-transform:none;
            }
            .product-reviews-summary {
                margin:0;
            }
            .price-box {
                text-align:center;
                span.price {
                    display:block;
                    text-align:center;
                    color:#fd1f55;
                    font-size:48px;
                    font-weight:700;
                    text-transform:uppercase;
                    line-height:normal;
                    font-family: "Montserrat";
                }
            }
            div.infos-br {
                text-align:center;
                padding-bottom:25px;
                p {
                    font-size:14px;
                    font-family: "Montserrat";
                    margin:0;
                }
            }
        }
        .product.media {
            width: 57%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .page-layout-1column .product-info-main {
        width:35%;
        margin-right:0;
        padding:15px;
        h2,
        p.titre {
            font-size:20px;
        }
        .box-tocart .actions .action.primary {
            padding:15px 10px;
            span {
                padding:4px 40px 4px 0;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}
.catalog-category-view {
    .page-main > .page-title-wrapper .page-title {
        font-weight:700;
    }
}
.catalog-product-view {
    .availability.only {
        display:none;
    }
    .product-info-main .stock {
        font-size:16px;
        color:#5c5c5c;
    }
    .product-info-main .stock span {
        display:inline-block;
        position:relative;
    }
    .product-info-main .stock span:before {
        display:inline-block;
        content:"";
        width:8px;
        height:8px;
        border-radius:50%;
        background:#5c5c5c;
        margin-right:5px;
    }
    .product-reviews-summary .reviews-actions a {
        color: #ff5c5c;
    }
    .product-info-main .price-box {
        font-size:18px;
        clear:both;
        .weee {
            font-size:18px;
        }
    }
    .product-info-price .netreviews-stars {
        margin-bottom:30px;
    }
    .product-info-price .netreviews-stars a {
        line-height: 21px;
    }
    .product-info-main .product-addto-links {
        a.action {
            padding: 14px 8px;
            font-size: 14px;
            background:#000;
            color:#FFF;
            display:none;
        }
        a.action.towishlist {
            border: none;
            border-radius: 0;
            display:inline-block;
            background:transparent url(../images/picto-wishlist-2.png) no-repeat 0 0;
            color:#000;
            padding:10px 0 10px 48px;
            span {
                display:block;
                font-size:11px;
                font-weight:400;
                font-family: Montserrat;
            }
        }
        a.action.tocompare {
            background:transparent url(../images/bt-box-black.png) no-repeat -46px 0;
            width: 41px;
            height: 39px;
            border: none;
            border-radius: 0;
            span {
                display:none;
            }
        }
    }
    .product-social-links {
        > .product-addto-links {
            float:none;
            margin:0;
            text-align:center;
        }
        a.action.mailto.friend {
            float:left;
            color:#000;
            margin:0;
            display:none;
            span {
                display:none;
            }
        }
        .action.mailto.friend:before {
            width: 40px;
            height: 37px;
            border:1px solid #000;
            font-size: 39px;
            line-height: 38px;
            margin-left:3px;
        }
    }
    @include max-screen($screen__m) {
        .product-social-links {
            @include clearfix();
            margin-bottom:20px;
        }
    }
}
body.catalog-category-view .category-view .category-description a.plus {
    display:none;
}
@media only screen and (min-width: 768px) {
    .catalog-product-view .fieldset > .field:not(.choice) > .control {
        width: 50%;
        float: left;
        padding-left: 16px;
    }
    body.catalog-category-view {
        .page-wrapper .nav-sections {
            margin-bottom:0;
        }
        h1.page-title {
            display:none;
        }
        #maincontent {
            background:#FFF;
            padding-left:0;
            padding-right:0;
        }
        div.columns {
            background:#FFF;
            padding-right:20px;
            padding-left:20px;
            padding-top:45px;
        }
        .page-wrapper > .breadcrumbs {
            margin-top:25px;
        }
        .page-title-wrapper {
            padding-left:20px;
            padding-top:45px;
            h1 {
                position:relative;
                font-size:48px;
                font-weight:900;
                text-transform:uppercase;
                font-family:Montserrat;
                margin-bottom:0;
                &:before {
                    content:"";
                    display:block;
                    width:88px;
                    height:4px;
                    background:#ff5c5c;
                    margin-bottom:10px;
                }
            }
        }
        .category-view {
            @include clearfix();
            .category-image {
                float:right;
                margin-top:-45px;
                margin-bottom:-39px;
                width:42%;
                position:relative;
                z-index:3;
            }
            .category-description {
                float:left;
                padding:25px 0 0 20px;
                p {
                    font-size:20px;
                    font-family:Montserrat;
                    margin-bottom:0;
                    line-height:140%;
                    font-weight:400;
                }
                .large-desc {
                    height:130px;
                    overflow:hidden;
                }
                a.plus {
                    color:#FFF;
                    display:block;
                    font-size:16px;
                    margin:15px 0;
                }
            }
        }
        .sidebar-main {
            width: 20.66667%;
        }
        .column.main {
            width:79.33333%;
        }
    }
}
.catalog-product-view {
    @media only screen and (min-width: 768px) {
        .page-title-wrapper {
            display:none;
        }
        .fieldset > .field.qty {
            margin-bottom:10px;
            .control {
                padding-left: 0;
                    float: none;
                    width: auto;
                    display: inline-block;
            }
            .label {
                float:none;
                width:auto;
            }
            .input-text.qty {
                width:59px;
                height:36px;
                background:#f6f6f6;
                font-size:14px;
                font-family:Montserrat;
                border:none;
            }
            label.label {
                text-transform:uppercase;
                font-size:14px;
                font-family:Montserrat;
                font-weight:400;
            }
        }
    }
}
.product-info-main {
    .product-social-links {
        ul.social {
            list-style:none;
            padding:0;
            margin:0;
            margin-bottom:35px;
            text-align:center;
            li {
                display:inline-block;
                margin:0;
                padding:0 10px 0 0;
                a {
                    display:block;
                    background:url(../images/social-product.png) no-repeat 0 0;
                    width:20px;
                    height:20px;
                    font-size:0;
                }
            }
            .tweet {
                a {
                    background-position:-41px 0;
                }
            }
            .pint {
                a {
                    background-position:-80px 0;
                }
            }
            .inst {
                a {
                    background-position:-111px 0;
                }
            }
        }
    }
    .options-product {
        padding-bottom:35px;
        div.opts {
            display:inline-block;
        }
        .phase-active {
            padding-left:15px;
        }
    }
    .ancre-links {
        ul {
            list-style:none;
            margin:0;
            padding:0;
            text-align:center;
            li {
                display:inline-block;
                padding:5px 15px;
                margin:0;
                a {
                    color:#000;
                    font-size:12px;
                    font-weight:400;
                    font-family: Montserrat;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .catalog-product-view {
        h1.page-title {
            margin: 0;
            font-size: 24px;
            font-weight: 700;
            font-family: "Montserrat";
            text-transform: uppercase;
            margin-bottom:15px;
        }
        .product-info-main {
            max-width:480px;
            padding-top:15px;
            text-align:left;
            h2,
            p.titre {
                display:none;
            }
            .stock {
                text-align:left;
            }
            .price-box span.price {
                display: block;
                text-align: left;
                color: #fd1f55;
                font-size: 48px;
                font-weight: 700;
                text-transform: uppercase;
                line-height: normal;
               
                font-family: "Montserrat";
            }
            div.infos-br p {
                font-size: 14px;
                font-family: "Montserrat";
                margin: 0;
                margin-bottom:15px;
            }
            .box-tocart {
                .action.primary {
                    width:100%;
                    background:#fd1f55;
                    color:#FFF;
                    font-size:18px;
                    font-family: Montserrat;
                    border:none;
                    span {
                        display:inline-block;
                        background: url(../images/picto-cart.png) no-repeat top right;
                        padding:4px 60px 4px 0;
                    }
                }
                .action.primary:hover {
                    border:none;
                    background:#000;
                }
                .field.qty {
                    .control {
                        display:inline-block;
                    }
                    .label {
                        padding-right:15px;
                    }
                }
            }
            .product-social-links {
                display:block;
            }
        }
    }
}
.column.main .block.related {
    @include clearfix();
    display:block;
    @media only screen and (max-width: 768px)  {
        order:3;
        width:100%;
    }
    @media only screen and (min-width: 640px) {
        .products-grid {
            .product-item {
                width:32.9%;
                display:inline-block!important;
                .product-item-info {
                    width:auto;
                }
                .product-image-container {
                    display:block;
                    margin:0 auto;
                }
            }
        }
    }
    @media only screen and (max-width: 640px) {
        .products-grid {
            .product-item {
                display:inline-block!important;
                padding-left:0;
                .product-item-info {
                    width:auto;
                }
            }
        }
    }
    .block-title.title {
        float:left;
        position:relative;
        width:25%;
        padding-top:125px;
        padding-left:65px;
        box-sizing:border-box;
        strong {
            font-size:36px;
            text-transform:uppercase;
            font-weight:400;
            font-family: "Montserrat";
            margin:0;
            > strong {
                display:block;
                font-weight:700;
            }
        }
        @media only screen and (max-width: 1024px) {
            padding-left:15px;
        }
        @media only screen and (max-width: 768px) {
            float:none;
            width:auto;
            padding-top:0;
            strong {
                font-size:34px;
                > strong {
                    display:inline;
                }
            }
        }
    }
    .block-title.title:after {
        display:block;
        width:88px;
        height:4px;
        background:#ff5c5c;
        content:"";
        margin-top:15px;
    }
    .block-content {
        float:right;
        width:74%;
        .products {
            margin:0;
        }
        .products.products-grid .product-item-name a {
            text-transform: none;
            color: #ff5c5c;
            font-size: 18px;
            font-weight:700;
        }
        .products.products-grid .price-box {
            font-size: 18px;
            color: #999999;
            font-weight: 700;
            font-family: "Montserrat";
        }
        @media only screen and (max-width: 768px) { 
            width:auto;
            float:none;
        }
    }
}
.expedition-el {
    p {
        font-size:12px;
        font-weight:400;
        text-align:center;
        margin:0;
        font-family: "Montserrat";
        padding:15px 0 0 0;
        color:#000;
        a {
            text-decoration:underline;
            color:#ff5c5c;
        }
    }
    @media only screen and (max-width: 768px) {
        p {
            text-align:left;
        }
    }
}
#checkout .authentication-wrapper {
   margin-top:0;
}
.columns #checkout .field-tooltip .label > span {
    display:none;
}