.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget {
    .product-item-info {
        width: auto;
        position:relative;
        .product-reviews-summary {
            display:none;
        }
    }
    .blackbox {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: rgba(0, 0, 0, 0.7);
        z-index:1;
        display:none;
        cursor:pointer;
        .inner-black-box {
            display:table;
            height:100%;
            width:100%;
            .outer-black-box {
                display:table-cell;
                vertical-align:middle;
                text-align:center;
            }
        }
        .product-item-actions {
            @include clearfix();
            display:inline-block;
            .actions-primary {
                float:left;
                padding-right:7px;
                .action.primary {
                    background:url(../images/bt-box.png) no-repeat top right;
                        width: 40px;
                        height: 39px;
                        border: none;
                        border-radius: 0;
                        padding: 0;
                        display: block;
                        opacity:0.5;
                    span {
                        display:none;
                    }
                }
                .action.primary:hover {
                    opacity:1;
                }
            }
            .actions-secondary {
                    float:left;
                    width:auto;
                    display:block;
                    padding:0;
                a.towishlist {
                    background:url(../images/bt-box.png) no-repeat -92px 0;
                    width: 40px;
                    height: 39px;
                    display:block;
                    margin-right:7px;
                    float:left;
                    opacity:0.5;
                }
                a.towishlist:before,
                a.tocompare:before {
                    display:none;
                }
                a.tocompare {
                    background:url(../images/bt-box.png) no-repeat -46px 0;
                    width: 40px;
                    height: 39px;
                    display:block;
                    float:left;
                    opacity:0.5;
                }
                a.towishlist:hover,
                a.tocompare:hover {
                    opacity:1;
                }
            }
        }
    }

    .pager {
        padding: 0;

        .toolbar-amount {
            float: none;
            @include lib-font-size(12);
        }

        .pages-item-previous {
            padding-left: 0;
        }

        .pages-item-next {
            position: relative;
        }

        .items {
            white-space: nowrap;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 3);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.widget .products-grid .product-item {
        width: (100% / 3);

        .sidebar & {
            margin-left: 0;
            width: 100%;

            .actions-secondary {
                display: block;
                padding: 10px 0;
            }
        }
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 2);
    }
}

@include min-screen($screen__l) {
    .block.widget .products-grid .product-item {
        width: (100% / 5);
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: calc((100% - 5 * (100% / 6)) / 4);
        width: (100% / 6);

        &:nth-child(4n + 1) {
            margin-left: calc((100% - 5 * (100% / 6)) / 4);
        }

        &:nth-child(5n + 1) {
            margin-left: 0;
        }
    }
    /* homepage */
    .page-layout-1column .block.widget .home-widget .products-grid .product-item {
        margin-right:2.93%;
        width:22.55%;
        margin-left:0;

        &:last-child {
            margin-left:0;
            margin-right:0;
        }
        .product-item-name {
            font-size:18px;
            text-align:center;
            a {
                text-transform:uppercase;
                text-decoration:none;
                font-weight:700;
            }
        }
        .price-box {
            margin:0;
            text-align:center;
            color:#707070;
            font-size:16px;
            font-weight:700;
            padding-bottom:35px;
            .price {
                display:block;
                color:#707070;
                font-size:16px;
                text-align:center;
            }
        }
    }
    .page-layout-1column .block.widget .home-widget .products-grid .product-item:hover {
        .blackbox {
            display:block;
        }
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .block.widget .products-grid .product-items {
        margin: 0;
    }

    .block.widget .products-grid .product-item {
        margin-left: calc((100% - 4 * 24.439%) / 3);
        padding: 0;
        width: 24.439%;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }
}

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
    .page-layout-1column .block.widget .home-widget .products-grid .product-item {
        .product-item-name {
            font-size:18px;
            text-align:center;
            a {
                text-transform:uppercase;
                text-decoration:none;
                font-weight:700;
            }
        }
        .price-box {
            margin:0;
            text-align:center;
            color:#707070;
            font-size:16px;
            font-weight:700;
            padding-bottom:35px;
            .price {
                display:block;
                color:#707070;
                font-size:16px;
                text-align:center;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .page-layout-1column .block.widget .home-widget .products-grid .product-item {
        width: 24.5%;
    }
}
@media only screen and (max-width: 640px) {
    .page-layout-1column .block.widget .home-widget .products-grid .product-item {
        width: 24.5%;
        .product-item-name {
            font-size:14px;
        }
    }
}
@media only screen and (max-width: 600px) {
    .page-layout-1column .block.widget .home-widget .products-grid .product-item {
        width: 49%;
    }
}

